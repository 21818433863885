<template>
  <div class="card">
    <Breadcrumb :model="items"/>
    <data-table class="p-mt-2" :value="list"
                :auto-layout="true"
                :scrollable="true" responsiveLayout="scroll">
      <Column style="min-width: 10rem" field="name" header="名字"></Column>
      <Column style="min-width: 10rem" field="industry" header="行业"></Column>
      <Column style="min-width: 10rem" field="phone" header="电话"></Column>
      <Column style="min-width: 20rem" field="learningExperience" header="学习经历"></Column>
      <Column style="min-width: 20rem" field="experienceOffice" header="任职经历"></Column>
      <Column style="min-width: 20rem" field="winning" header="获奖经历"></Column>
      <Column style="min-width: 10rem" field="addr" header="地址"></Column>
      <Column style="min-width: 10rem" field="age" header="年龄"></Column>
      <Column style="min-width: 10rem" field="pic" header="照片">
        <template #body="a">
          <img style="width: 50px;height: 50px" :src="url+a.data.pic"/>
        </template>
      </Column>
<!--      <Column style="min-width: 10rem" field="email" header="邮箱"></Column>-->
      <Column style="min-width: 8rem;" field="state" header="状态">
        <template #body="a">
          {{a.data.state===0?'未审核':(a.data.state===1?'通过':'拒绝')}}
        </template>
      </Column>
      <Column frozen alignFrozen="right" style="min-width: 20rem" :exportable="false" header="操作">
        <template #body="a">
          <Button class="p-button-sm p-mr-2" @click="checkSate(a.data.id,1)">通过</Button>
          <Button class="p-button-sm" @click="checkSate(a.data.id,2)">拒绝</Button>
        </template>
      </Column>
    </data-table>
    <Paginator @page="changePage" :rows="page.size" :totalRecords="page.total" :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
               template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"></Paginator>
  </div>
</template>

<script>
export default {
  name: "expertResume",
  data() {
    return {
      items: [
        {label: '智库管理', to: '/'},
        {label: '履历审核', to: '/expertResume'}
      ],
      list: [],
      page: {
        current: 1,
        size: 10,
        total: 0
      },
      url:this.$global_msg.imgurl
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get('/expertResume/getExpertResumeList', {
        params: {
          current: this.page.current,
          size: this.page.size
        }
      }).then((res) => {
        console.log(res.data)
        this.page.total = res.data.total
        this.list = res.data.records
      })
    },
    changePage(event) {
      this.page.size = event.rows
      this.page.current = event.page + 1
      this.getData()
    },
    checkSate(id,state){
      this.$http.post('/expertResume/checkResume/'+id+'/'+state).then(()=>{
        this.$toast.add({severity: 'success', summary: '成功', detail: '审核成功', life: 3000});
        this.getData()
      })
    }
  }
}
</script>

<style scoped>

</style>